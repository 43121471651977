import { type ComponentProps, forwardRef } from "react";

import { cn } from "~/lib/utils";

export const FlexRow = forwardRef<HTMLDivElement, ComponentProps<"div">>(
  function FlexRowWithForwardedRef({ className, ...restProps }, ref) {
    return (
      <div
        className={cn("flex flex-row", className)}
        ref={ref}
        {...restProps}
      />
    );
  },
);
